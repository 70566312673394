import { ACCREDITATION_API_BASE_URL, getHeaders } from './index'
import axios from 'axios'

export const getCnpjInfo = async cnpj => {
  try {
    const response = await axios.get(
      `${ACCREDITATION_API_BASE_URL}/cnpj/${cnpj}`,
      {
        headers: getHeaders()
      }
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const getCnpjCnaes = async cnpj => {
  try {
    const response = await axios.get(
      `${ACCREDITATION_API_BASE_URL}/cnpj/cnaes/${cnpj}`,
      {
        headers: getHeaders()
      }
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const getCnaes = async () => {
  try {
    const response = await axios.get(
      `${ACCREDITATION_API_BASE_URL}/fiserv/cnaes`,
      {
        headers: getHeaders()
      }
    )
    return response.data
  } catch (err) {
    throw err
  }
}
