import axios from 'axios'
import { ACCREDITATION_API_BASE_URL, getHeaders } from './index'

export const sendProposal = async proposalPayload => {
  try {
    const response = await axios.post(
      `${ACCREDITATION_API_BASE_URL}/fiserv/send-proposal`,
      proposalPayload,
      {
        headers: getHeaders()
      }
    )
    return response
  } catch (err) {
    throw err
  }
}

export const sendProposalRiskTeam = async proposalId => {
  try {
    const response = await axios.post(
      `${ACCREDITATION_API_BASE_URL}/fiserv/send-proposal-analysis-team/${proposalId}`,
      {},
      {
        headers: getHeaders()
      }
    )
    return response
  } catch (err) {
    throw err
  }
}
