import { ACCREDITATION_API_BASE_URL, getHeaders } from './index'
import axios from 'axios'

export const getBanks = async () => {
  try {
    const response = await axios.get(
      `${ACCREDITATION_API_BASE_URL}/fiserv/banks`,
      {
        headers: getHeaders()
      }
    )

    response.data.push({
      bankNumber: '450',
      bankName: 'FITBANK'
    })

    return response.data
  } catch (err) {
    throw err
  }
}
