import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    border: 0;
    background-color: #FAFAFA;
    height: 100%;
    overflow: hidden;
  }

  #root {
    min-width: 100vw;
    min-height: 100vh;
  }

  body, button, input {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    font-size: 16px;
  }

  .title-page-info{
    font-size: 14pt;
    margin-top: 2.5em;
  }

  .form-container{
    padding: 2em;
    background-color: white;
    webkit-box-shadow: 0px 3px 16px -1px rgba(209,209,209,1);
    -moz-box-shadow: 0px 3px 16px -1px rgba(209,209,209,1);
    box-shadow: 0px 3px 8px -1px rgba(209,209,209,1);
  }

  .table-container{
    width: 50em;
    padding: 2em;
    background-color: white;
    -webkit-box-shadow: 0px 3px 16px -1px rgba(209,209,209,1);
    -moz-box-shadow: 0px 3px 16px -1px rgba(209,209,209,1);
    box-shadow: 0px 3px 16px -1px rgba(209,209,209,1);
  }

  .editable-cell-value-wrap{
    cursor: pointer;
  }

  .form-container-product-add{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container-options{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-result-subtitle{
    font-size: 14pt !important;
    color: rgba(0, 0, 0, 0.88) important!;
  }

  .page-loader{
    position: absolute;
  }

  .accreditation-status{
    &-processing{
      color: orange;
    }

    &-waiting-se-config{
      color: #3271a8;
    }

    &-refused{
      color: red;
    }

    &-approved{
      color: green;
    }
  }

  .ant-modal-body{
    display: flex;
    justify-content: center;
    padding: 1em;
  }

  .ant-table-cell{
    background: #FFFFFF !important;
  }
`
