import React, { useState } from 'react'
import { Button, Form, Input, Spin, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { login } from '../../providers/api/login'
import * as LoginComponents from './style'
import { LoadingOutlined } from '@ant-design/icons'

const Login = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const navigate = useNavigate()
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />
  const [requestLoading, setRequestLoading] = useState(false)

  const handleOnFinish = async values => {
    setRequestLoading(true)
    const response = await login(values)
    setRequestLoading(false)
    if (!response.status) {
      messageApi.open({
        type: 'error',
        content: 'Credenciais Invalidas.'
      })
      return
    }

    navigate('/')
  }

  return (
    <LoginComponents.FormContainer>
      <Spin
        className="page-loader"
        indicator={spinIcon}
        spinning={requestLoading}
      >
        {contextHolder}
        <header
          className="portal-header"
          style={{
            marginBottom: '2em',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center'
          }}
        >
          <img
            style={{ height: '50px' }}
            alt="x-payLogo"
            src={require('../../assets/img/logo.png')}
          ></img>
          <div style={{ fontSize: '16pt' }}>Portal de Credenciamento</div>
        </header>
        <Form
          useRef
          name="step_one"
          style={{ width: 470 }}
          onFinish={handleOnFinish}
        >
          <Form.Item
            label="Login"
            name="login"
            rules={[{ required: true, message: 'Informe o Login!' }]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item
            label="Senha"
            name="password"
            rules={[{ required: true, message: 'Informe a senha!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </LoginComponents.FormContainer>
  )
}

export default Login
