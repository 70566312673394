import { ACCREDITATION_API_BASE_URL, getHeaders } from './index'
import axios from 'axios'

export const getTaxs = async payload => {
  const { anualBilling, document, type, cnae, campaignId, uf } = payload

  try {
    const route = `${ACCREDITATION_API_BASE_URL}/fiserv/taxs?campaignId=${campaignId}&anualBilling=${anualBilling}&document=${document}&cnae=${cnae}&uf=${uf}&type=${type}`

    const response = await axios.get(route, {
      headers: getHeaders()
    })

    return response.data
  } catch (err) {
    throw err
  }
}
