import { ACCREDITATION_API_BASE_URL, getHeaders } from './index'
import axios from 'axios'

export const getAntecipation = async payload => {
  const { anualBilling, cnae, campaignId } = payload

  try {
    const route = `${ACCREDITATION_API_BASE_URL}/fiserv/antecipation?campaignId=${campaignId}&anualBilling=${anualBilling}&cnae=${cnae}`

    const response = await axios.get(route, {
      headers: getHeaders()
    })
    return response.data
  } catch (err) {
    throw err
  }
}
