import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PersonalInfoAddress from '../02-personal-info-address/index'
import {
  cleanCpfCnpj,
  maskCpfCnpj,
  maskPhoneNumber,
  unmaskCNPJ
} from '../../utils/masks'
import { getCnpjInfo } from '../../utils/cnpj-info'
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select
} from 'antd'
import dayjs from 'dayjs'
import { checkLogin } from '../../providers/api/login'
import Cookies from 'js-cookies'
import AppLayout from '../../components/app-layout/app-layout'
import $ from 'jquery'

export const PersonalInfo = () => {
  const routerParams = useParams()
  const navigate = useNavigate()

  const [formData, setFormData] = useState({})
  const [nextSteps, setNextStep] = useState(false)
  const [form] = Form.useForm()
  const [formInitialValues, setFormInitialValues] = useState({
    document: '',
    founding_date: '',
    email: '',
    social_name: '',
    company_name: '',
    name: '',
    birthDate: '',
    website: '',
    nationality: '',
    telephone: '',
    cellphone: '',
    political_exposed: '',
    contact_using_data: '',
    data_for_receive: '',
    cityInscription: '',
    uf: '',
    localBirth: '',
    motherName: '',
    rg: ''
  })
  const [pfOrPj, setPfOrPj] = useState('')
  const formMaskFunctions = {
    document: value => maskCpfCnpj(value),
    cellphone: value => maskPhoneNumber(value),
    telephone: value => maskPhoneNumber(value)
  }

  const handleInputsMask = inputChanged => {
    const currentFormInitialValues = form.getFieldsValue()

    const inputName = Object.keys(inputChanged)[0]

    if (formMaskFunctions[inputName]) {
      currentFormInitialValues[inputName] = formMaskFunctions[inputName](
        inputChanged[inputName]
      )

      form.setFieldsValue(currentFormInitialValues)
    }

    if (inputName === 'document') {
      if (currentFormInitialValues.document.length < 14) {
        setPfOrPj('')
      }
    }

    if (inputName === 'political_exposed') {
      setFormInitialValues(currentFormInitialValues)
    }
  }

  const handleConfirmDocument = () => {
    const currentFormInitialValues = form.getFieldsValue()
    if (currentFormInitialValues.document.length === 18) {
      setPfOrPj('pj')
      handleCnpjInfo()
    } else if (currentFormInitialValues.document.length === 14) {
      setPfOrPj('pf')
    } else {
      setPfOrPj('')
    }
  }

  const handleCnpjInfo = async () => {
    const currentFormInitialValues = form.getFieldsValue()

    const cnpj = unmaskCNPJ(currentFormInitialValues.document)
    const cnpjInfo = await getCnpjInfo(cnpj)
    const companyData = {}

    if (!cnpjInfo.error) {
      for (const key of Object.keys(cnpjInfo)) {
        const cleankey = key.replace(/\s/g, '')
        companyData[cleankey] = cnpjInfo[key]
      }

      currentFormInitialValues.social_name = companyData.RAZAOSOCIAL
      currentFormInitialValues.company_name = companyData.NOMEFANTASIA
      currentFormInitialValues.founding_date = dayjs(
        companyData.DATAABERTURA,
        'DD/MM/YYYY'
      )
      currentFormInitialValues.cityInscription = companyData.MUNICIPIO
      currentFormInitialValues.stateInscription = companyData.UF
      form.setFieldsValue(currentFormInitialValues)
      setFormData(companyData)
    }
  }

  const handleOnFinish = data => {
    data.birthDate = dayjs(data.birthDate).format('YYYY-MM-DD')
    data.founding_date = dayjs(data.founding_date).format('YYYY-MM-DD')

    const payload = {}

    const cleanedDocument = cleanCpfCnpj(data.document)
    if (cleanedDocument.length === 11) {
      const splitedName = data.name.split(' ')

      payload.physicalPerson = {
        birthDate: data.birthDate,
        cpf: cleanedDocument,
        name: data.name,
        nationality: data.nationality,
        pepIndicator: data.political_exposed,
        surname: splitedName[splitedName.length - 1],
        localBirth: data.localBirth,
        motherName: data.motherName
      }

      if (data.political_exposed) {
        payload.physicalPerson.politicallyExposedPerson = {
          governmentAgency: data.political_exposed_government_agency,
          name: data.political_exposed_name,
          responsibility: data.political_exposed_position
        }
      }
    } else {
      payload.legalPerson = {
        cnpj: cleanedDocument,
        cityInscription: data.cityInscription,
        dateConstitution: data.founding_date,
        fantasyName: data.company_name,
        openDate: data.founding_date,
        socialReason: data.social_name,
        stateInscription: data.stateInscription,
        formConstitution: data.formConstitution
      }
    }

    const splitedCellphone = data.cellphone.split(' ')
    const splitedFixphone = data.telephone.split(' ')

    payload.email = data.email
    payload.fixedDdd = `0${splitedFixphone[0].replace('(', '').replace(')', '')}`
    payload.fixedNumber = splitedFixphone[1].replace('-', '')
    payload.mobileDdd = `0${splitedCellphone[0].replace('(', '').replace(')', '')}`
    payload.mobileNumber = splitedCellphone[1].replace('-', '')
    // payload['rg'] = data.rg

    setFormData(payload)
    setNextStep(true)
  }

  const handleCheckAuth = async () => {
    const token = routerParams.token || Cookies.getItem('token')
    if (token) {
      const response = await checkLogin(token)

      if (!response.status) {
        Cookies.removeItem('token')
        return navigate('/login')
      }

      if (routerParams.token) {
        const url = window.location.toString()
        return (window.location = url.replace(routerParams.token, ''))
      }

      // if (Cookies.getItem('token')) {
      // }
    } else {
      navigate('/login')
    }
  }

  useEffect(() => {
    handleCheckAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    $('.ant-picker-input')
      .find('input')
      .attr('maxlength', '10')
      .mask('99/99/9999')
  })

  return (
    <>
      {!nextSteps && (
        <AppLayout>
          <p className="title-page-info"> Dados cadastrais</p>
          <div className="form-container">
            <Form
              useRef
              form={form}
              name="step_one"
              style={{ width: 800 }}
              initialValues={formInitialValues}
              onFinish={handleOnFinish}
              onValuesChange={handleInputsMask}
            >
              <Row gutter={20}>
                <Col xs={5} sm={9} md={14} lg={22} xl={22}>
                  <Form.Item
                    label="CPF/CNPJ"
                    name="document"
                    rules={[{ required: true, message: 'Informe o CPF/CNPJ' }]}
                  >
                    <Input maxLength={18} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={15} md={10} lg={2} xl={1}>
                  <Form.Item>
                    <Button
                      onClick={handleConfirmDocument}
                      type="primary"
                      htmlType="button"
                    >
                      Confirmar
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              {pfOrPj === 'pj' && (
                <>
                  {/* <Row gutter={16}> */}
                  {/* <Col xs={24} sm={24} md={24} lg={24} xl={12}> */}
                  {pfOrPj === 'pj' && (
                    <Form.Item
                      label="Razão Social"
                      name="social_name"
                      rules={[
                        { required: true, message: 'Informe a Razão Social.' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                  {/* </Col> */}
                  {/* <Col xs={24} sm={24} md={24} lg={24} xl={12} > */}
                  {pfOrPj === 'pj' && (
                    <Form.Item
                      label="Nome Fantasia"
                      name="company_name"
                      rules={[
                        { required: true, message: 'Informe o Nome Fantasia.' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                  {/* </Col> */}
                  {/* </Row> */}

                  {/* <Row gutter={16}> */}
                  {/* <Col xs={24} sm={24} md={24} lg={24} xl={12}> */}
                  {pfOrPj === 'pj' && (
                    <Form.Item
                      label="Data da Fundação"
                      name="founding_date"
                      rules={[
                        {
                          required: true,
                          message: 'Informe a Data da fundação.'
                        }
                      ]}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        placeholder="Selecione uma data"
                        format="DD/MM/YYYY"
                        onBlur={date => {
                          const currentFormInitialValues = form.getFieldsValue()
                          currentFormInitialValues.founding_date = dayjs(
                            date.target.value,
                            'DD/MM/YYYY'
                          )
                          if (!date.target.value || date.target.value === '') {
                          } else {
                            form.setFieldsValue(currentFormInitialValues)
                          }
                        }}
                      />
                    </Form.Item>
                  )}
                  {/* </Col> */}
                  {/* <Col xs={24} sm={24} md={24} lg={24} xl={12} > */}
                  {pfOrPj === 'pj' && (
                    <Form.Item
                      label="Tipo de Constituição"
                      name="formConstitution"
                      rules={[
                        {
                          required: true,
                          message: 'Informe o Tipo de Constituição'
                        }
                      ]}
                    >
                      <Select
                        options={[
                          { value: 1, label: 'Empresário Individual - EI' },
                          {
                            value: 2,
                            label: 'Microempreendedor Individual - MEI'
                          },
                          {
                            value: 3,
                            label:
                              'Empresa Individual de Responsabilidade Limitada - EIRELI'
                          },
                          { value: 4, label: 'Sociedade Empresária' },
                          { value: 5, label: 'Sociedade Simples' },
                          { value: 6, label: 'Sociedade Anônima - SA' },
                          { value: 7, label: 'Sociedade Limitada - LTDA' },
                          { value: 8, label: 'Micro Empresa - ME' },
                          { value: 9, label: 'Empresa Pequeno Porte' },
                          { value: 10, label: 'Empresa Médio Porte' },
                          { value: 11, label: 'Empresa Grande Porte' }
                        ]}
                      />
                    </Form.Item>
                  )}
                  {/* </Col> */}
                  {/* </Row> */}

                  {/* <Row gutter={16}> */}
                  {/* <Col xs={24} sm={24} md={24} lg={24} xl={12}> */}
                  {pfOrPj === 'pj' && (
                    <Form.Item
                      label="Município"
                      name="cityInscription"
                      rules={[
                        { required: true, message: 'Informe o Município.' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}

                  {/* </Col> */}
                  {/* <Col xs={24} sm={24} md={24} lg={24} xl={12} > */}

                  {pfOrPj === 'pj' && (
                    <Form.Item
                      label="Estado"
                      name="stateInscription"
                      rules={[{ required: true, message: 'Informe o Estado.' }]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                  {/* </Col> */}
                  {/* </Row>        */}
                  <Divider />
                </>
              )}
              {pfOrPj === 'pf' && (
                <>
                  {/* <Row gutter={16}> */}
                  {/* <Col xs={24} sm={24} md={24} lg={24} xl={12}> */}

                  {pfOrPj === 'pf' && (
                    <Form.Item
                      label="Nome"
                      name="name"
                      rules={[{ required: true, message: 'Informe o Nome.' }]}
                    >
                      <Input />
                    </Form.Item>
                  )}

                  {/* </Col> */}
                  {/* <Col xs={24} sm={24} md={24} lg={24} xl={12} >     */}
                  {pfOrPj === 'pf' && (
                    <Form.Item
                      label="Data de nascimento"
                      name="birthDate"
                      rules={[
                        {
                          required: true,
                          message: 'Informe a Data de nascimento.'
                        }
                      ]}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        placeholder="Selecione uma data"
                        format="DD/MM/YYYY"
                        onBlur={date => {
                          const currentFormInitialValues = form.getFieldsValue()
                          currentFormInitialValues.birthDate = dayjs(
                            date.target.value,
                            'DD/MM/YYYY'
                          )

                          if (!date.target.value || date.target.value === '') {
                          } else {
                            form.setFieldsValue(currentFormInitialValues)
                          }
                        }}
                      />
                    </Form.Item>
                  )}
                  {/* </Col> */}
                  {/* </Row>         */}

                  {/* <Row gutter={16}> */}
                  {/* <Col xs={24} sm={24} md={24} lg={24} xl={12}> */}

                  {pfOrPj === 'pf' && (
                    <Form.Item
                      label="Nacionalidade"
                      name="nationality"
                      rules={[
                        { required: true, message: 'Informe a Nacionalidade.' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}

                  {/* </Col> */}
                  {/* <Col xs={24} sm={24} md={24} lg={24} xl={12} >     */}
                  {pfOrPj === 'pf' && (
                    <Form.Item
                      label="Cidade de Nascimento"
                      name="localBirth"
                      rules={[
                        {
                          required: true,
                          message: 'Informe a cidade de nascimento.'
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                  {/* </Col> */}
                  {/* </Row>         */}

                  {/* <Row gutter={16}> */}
                  {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}> */}
                  {pfOrPj === 'pf' && (
                    <Form.Item
                      label="Nome da mãe"
                      name="motherName"
                      rules={[
                        { required: true, message: 'Informe o nome da mãe.' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}

                  {/* </Col> */}
                  {/* </Row>         */}

                  <Divider />
                </>
              )}

              {(pfOrPj === 'pf' || pfOrPj === 'pj') && (
                <>
                  {/* <Row gutter={16}> */}
                  {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>           */}
                  <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[{ required: true, message: 'Informe o E-mail.' }]}
                  >
                    <Input />
                  </Form.Item>
                  {/* </Col> */}
                  {/* </Row>         */}

                  {/* <Row gutter={16}> */}
                  {/* <Col xs={24} sm={24} md={24} lg={24} xl={12}>           */}
                  <Form.Item
                    label="Telefone"
                    name="telephone"
                    rules={[{ required: true, message: 'Informe o Telefone.' }]}
                  >
                    <Input maxLength={15} />
                  </Form.Item>
                  {/* </Col> */}
                  {/* <Col xs={24} sm={24} md={24} lg={24} xl={12}>           */}
                  <Form.Item
                    label="Celular"
                    name="cellphone"
                    rules={[{ required: true, message: 'Informe o Celular.' }]}
                  >
                    <Input maxLength={15} />
                  </Form.Item>
                  {/* </Col> */}
                  {/* </Row>         */}

                  <Divider />
                </>
              )}
              {pfOrPj === 'pf' && (
                <>
                  <Form.Item
                    label="Pessoa Politicamente Exposta?"
                    name="political_exposed"
                    rules={[
                      { required: true, message: 'Informe uma resposta' }
                    ]}
                  >
                    <Radio.Group>
                      <Radio value={true}>Sim</Radio>
                      <Radio value={false}>Não</Radio>
                    </Radio.Group>
                  </Form.Item>

                  {formInitialValues.political_exposed && (
                    <>
                      <Form.Item
                        label="Nome"
                        name="political_exposed_name"
                        rules={[{ required: true, message: 'Informe o nome.' }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Cargo"
                        name="political_exposed_position"
                        rules={[
                          { required: true, message: 'Informe o cargo.' }
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Orgão do Governo"
                        name="political_exposed_government_agency"
                        rules={[
                          {
                            required: true,
                            message: 'Informe o orgão do governo.'
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </>
                  )}

                  {/* <Form.Item
                            label="Utilizar dados para contato?"
                            name="contact_using_data"
                            rules={[{ required: true, message: 'Informe uma resposta' }]}
                            >
                            <Radio.Group>
                                    <Radio value={true}>Sim</Radio>
                                    <Radio value={false}>Não</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                            label="Utilizar dados para recebimento?"
                            name="data_for_receive"
                            rules={[{ required: true, message: 'Informe uma resposta' }]}
                            >
                            <Radio.Group>
                                    <Radio value={true}>Sim</Radio>
                                    <Radio value={false}>Não</Radio>
                                </Radio.Group>
                            </Form.Item> */}
                </>
              )}

              {(pfOrPj === 'pf' || pfOrPj === 'pj') && (
                <Form.Item wrapperCol={{ span: 10 }}>
                  <Button type="primary" htmlType="submit">
                    Próxima Etapa
                  </Button>
                </Form.Item>
              )}
            </Form>
          </div>
        </AppLayout>
      )}
      {nextSteps && <PersonalInfoAddress previousStepData={formData} />}
    </>
  )
}
