import { ACCREDITATION_API_BASE_URL, getHeaders } from './index'
import axios from 'axios'

export const getCampaign = async payload => {
  try {
    const { cnae, anualBilling, cnpj } = payload

    const response = await axios.get(
      `${ACCREDITATION_API_BASE_URL}/fiserv/campaigns?cnae=${String(cnae)}&anualBilling=${anualBilling}&cnpj=${cnpj}`,
      {
        headers: getHeaders()
      }
    )

    return response.data
  } catch (err) {
    throw err
  }
}
