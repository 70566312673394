import { ACCREDITATION_API_BASE_URL, getHeaders } from './index'
import axios from 'axios'

export const getTechnologies = async () => {
  try {
    const response = await axios.get(
      `${ACCREDITATION_API_BASE_URL}/fiserv/capture-solutions`,
      {
        headers: getHeaders()
      }
    )
    return response.data
  } catch (err) {
    throw err
  }
}
