import React from 'react'
import ReactDOM from 'react-dom/client'
import AppRoutes from './services/routers'
import { GlobalStyle } from './style/main'
import $ from 'jquery'
import 'jquery-mask-plugin/dist/jquery.mask.min.js'
import { UserProvider } from './Context/aplicationProvider'
window.jQuery = $
window.$ = $

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <UserProvider>
    <GlobalStyle />
    <AppRoutes />
  </UserProvider>
)

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister()

    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(async names => {
        await Promise.all(names.map(name => caches.delete(name)))
      })
    }
  })
}
