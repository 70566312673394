export const STATUS_TRANSACTION = {
  APROVADA: 'APROVADA',
  CONFIRMADA: 'CONFIRMADA',
  ESTORNADA: 'ESTORNADA',
  REPETIDA: 'REPETIDA',
  REVISAO_ANTIFRAUDE: 'REVISAO_ANTIFRAUDE',
  NEGADA: 'NEGADA',
  NEGADA_ANTIFRAUDE: 'NEGADA_ANTIFRAUDE',
  NEGADA_ADMINISTRADOR: 'NEGADA_ADMINISTRADOR',
  CANCELADA: 'CANCELADA',
  PENDENTE: 'PENDENTE',
  AGUARDANDO: 'AGUARDANDO',
  ERRO_AUTORIZACAO: 'ERRO_AUTORIZACAO',
  ERRO_CONFIRMACAO: 'ERRO_CONFIRMACAO',
  ERRO_TIMEOUT: 'ERRO_TIMEOUT',
  ERRO_DESFAZIMENTO: 'ERRO_DESFAZIMENTO',
  EXPIRADA: 'EXPIRADA',
  DESCONHECIDO: 'DESCONHECIDO'
}

export const statusTransactionsOptionsSelect = [
  { value: STATUS_TRANSACTION.APROVADA, label: 'Aprovada' },
  { value: STATUS_TRANSACTION.CONFIRMADA, label: 'Confirmada' },
  { value: STATUS_TRANSACTION.ESTORNADA, label: 'Estornada' },
  { value: STATUS_TRANSACTION.REPETIDA, label: 'Repetida' },
  { value: STATUS_TRANSACTION.REVISAO_ANTIFRAUDE, label: 'Revisão antifraude' },
  { value: STATUS_TRANSACTION.NEGADA, label: 'Negada' },
  { value: STATUS_TRANSACTION.NEGADA_ANTIFRAUDE, label: 'Negada antifraude' },
  {
    value: STATUS_TRANSACTION.NEGADA_ADMINISTRADOR,
    label: 'Negada por administrador'
  },
  { value: STATUS_TRANSACTION.CANCELADA, label: 'Cancelada' },
  { value: STATUS_TRANSACTION.PENDENTE, label: 'Pendente' },
  { value: STATUS_TRANSACTION.AGUARDANDO, label: 'Aguardando' },
  { value: STATUS_TRANSACTION.ERRO_AUTORIZACAO, label: 'Erro na autorização' },
  { value: STATUS_TRANSACTION.ERRO_CONFIRMACAO, label: 'Erro de confirmação' },
  {
    value: STATUS_TRANSACTION.ERRO_TIMEOUT,
    label: 'Erro por tempo de resposta'
  },
  {
    value: STATUS_TRANSACTION.ERRO_DESFAZIMENTO,
    label: 'Erro no desfazimento'
  },
  { value: STATUS_TRANSACTION.EXPIRADA, label: 'Expirada' },
  { value: STATUS_TRANSACTION.DESCONHECIDO, label: 'Desconhecido' }
]
