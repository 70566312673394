import axios from 'axios'
import Cookies from 'js-cookies'

import { ACCREDITATION_API_BASE_URL } from './index'

const login = async payload => {
  try {
    const response = await axios.post(
      `${ACCREDITATION_API_BASE_URL}/login`,
      payload
    )

    const { token, idCompany, idUser, accessLevel, userName } = response.data

    Cookies.setItem('token', token)
    Cookies.setItem('companyId', idCompany)
    Cookies.setItem('userId', idUser)
    Cookies.setItem('accessLevel', accessLevel)
    Cookies.setItem('userName', userName)

    return {
      status: true
    }
  } catch (err) {
    return {
      status: false
    }
  }
}

const checkLogin = async token => {
  try {
    const response = await axios.get(
      `${ACCREDITATION_API_BASE_URL}/login/check`,
      {
        headers: {
          Authorization: token
        }
      }
    )

    const { idCompany, idUser, accessLevel, userName } = response.data

    Cookies.setItem('token', token)
    Cookies.setItem('companyId', idCompany)
    Cookies.setItem('userId', idUser)
    Cookies.setItem('accessLevel', accessLevel)
    Cookies.setItem('userName', userName)

    return {
      status: true
    }
  } catch (err) {
    return {
      status: false
    }
  }
}

export { login, checkLogin }
